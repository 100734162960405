import {
  web3Accounts,
  web3Enable,
  web3FromAddress
} from '@polkadot/extension-dapp';
import { ApiPromise, WsProvider } from '@polkadot/api';
import Web3 from 'web3';

if (!Web3.givenProvider) {
  alert("Please install Metamask")
}
const web3 = new Web3(Web3.givenProvider);

const {
  encodeAddress,
  decodeAddress,
  signatureVerify
} = require('@polkadot/util-crypto');
const {
  hexToU8a,
  u8aToHex,
  stringToHex,
  isHex
} = require('@polkadot/util');


const parseBNtoString = require('../utils/parseBNtoString')



let globalApi;
async function getApi() {
  if (globalApi && globalApi.isConnected) {
    return globalApi
  }
  console.log(web3.currentProvider)
  if (!web3.currentProvider.chainId) {
    return
  }
  const deeperChainWss = (web3.currentProvider.chainId/1 == 1 || web3.currentProvider.chainId/1 == 56) ? "wss://mainnet-deeper-chain.deeper.network": "wss://mainnet-dev.deeper.network"
  if (deeperChainWss === "wss://mainnet-dev.deeper.network") {
    window.$Dialog.alert({
      message: window.$$i18n.t('swap.testnetError')
    })
  }
  const wsProvider = new WsProvider(deeperChainWss);
  globalApi = await ApiPromise.create({ provider: wsProvider});
  return globalApi
}

function isValidSignature(signedMessage, signature, address) {
  const publicKey = decodeAddress(address);
  const hexPublicKey = u8aToHex(publicKey);
  return signatureVerify(signedMessage, signature, hexPublicKey).isValid;
}

export default {
  encodeDeeperChain(address) {
    return encodeAddress(hexToU8a(address))
  },
  async signMessage(address, text) {
    console.log('signMessage')
    const injector = await web3FromAddress(address)
    const { signature } = await injector.signer.signRaw({
      address: address,
      data: stringToHex(text),
      type: 'bytes'
    });
    let isValid = isValidSignature(`<Bytes>${text}</Bytes>`, signature, address);
    console.log(isValid)
    return signature;
  },
  async init() {
    
    await web3Enable('DEEPERNETWORK').catch(e => {
      window.$Dialog.alert({
        message: window.$$i18n.t('swap.noPolkadot')
      })
    })
  },
  async getAccount() {
    let accounts = await web3Accounts();
    return accounts
  },
  async getBalance(address) {
    let api = await getApi()
    const { nonce, data: balance } = await api.query.system.account(address);
    return balance.free.toString()
  },
  async sendToEth({
    from,
    to,
    amount
  }) {
    let api = await getApi()
    const substrateWeb3 = await web3FromAddress(from);
    api.setSigner(substrateWeb3.signer);
    const transfer = api.tx.bridge.setTransfer(to, parseBNtoString(amount));
    return new Promise((resolve, reject) => {
      transfer.signAndSend(from, result => {
        if (result.status.isInBlock) {
          console.log('in a block');
        } else if (result.status.isFinalized) {
          console.log('finalized');
          result.events.forEach(({ event: { data, method, section }, phase }) => {
            if (section == "system" && method == "ExtrinsicFailed") {
                const [dispatchError] = data;
                if (dispatchError.isModule) {
                    const errorInfo = api.registry.findMetaError(dispatchError.asModule);
                    reject({message: `runExtrinsic failed: ${errorInfo.section}.${errorInfo.name} `});
                } else {
                    reject({message: `runExtrinsic failed: ${dispatchError.toString()}`});
                }
            }else if(section == "system" && method == "ExtrinsicSuccess"){
                resolve();
            }
          });
        }
      }).catch(e => {
        reject(e)
      })
    }) 
  },
  isValidAddressPolkadotAddress(address) {
    try {
      encodeAddress(
        isHex(address)
          ? hexToU8a(address)
          : decodeAddress(address)
      );
  
      return true;
    } catch (error) {
      return false;
    }
  }
}
