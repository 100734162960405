import Web3 from "web3";
const ETHCONFIG = require("./eth.config");
const parseBNtoString = require("../utils/parseBNtoString");
const { decodeAddress, encodeAddress } = require("@polkadot/keyring");
const { u8aToHex, hexToU8a } = require("@polkadot/util");

if (!Web3.givenProvider) {
  window.$Dialog.alert({
    message: window.$$i18n.t("swap.noMetamask"),
  });
}

const web3 = new Web3(Web3.givenProvider);
if (!web3.currentProvider.chainId) {
  window.$Toast.loading();
  // setTimeout(() => {window.location.reload()}, 3000)
}
let $chainID = web3.currentProvider.chainId / 1 || 1;
if ($chainID !== 1) {
  window.$Toast.loading();
  window.ethereum
    .request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }], // Ethereum mainnet chain ID
    })
    .then((res) => {
      console.log("Successfully switched to Ethereum mainnet", res);
      window.location.reload();
    })
    .catch((e) => {
      // This error code indicates that the chain has not been added to MetaMask
      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }], // Ethereum mainnet chain ID
        })
        .then((res) => {
          console.log("Successfully switched to Ethereum mainnet", res);
          window.location.reload();
        });
    });
}

window.$chainID = $chainID;
const Bridge = {
  address: ETHCONFIG[$chainID]?.contracts.bridge,
  abi: ETHCONFIG[$chainID]?.abi.bridge,
};
const DPR = {
  address: ETHCONFIG[$chainID]?.contracts.dpr,
  abi: ETHCONFIG[$chainID]?.abi.dpr,
};

console.log(Bridge);
console.log(DPR);

const dprContract = new web3.eth.Contract(DPR.abi, DPR.address);
const bridgeContract = new web3.eth.Contract(Bridge.abi, Bridge.address);
const BN = Web3.utils.BN;
let initGetAccount = false;
async function init({ onAccountsChanged, onChainChanged, onMessage }) {
  return new Promise((resolve, reject) => {
    if (typeof window.ethereum === "undefined") {
      reject("MetaMask is not installed!");
      return;
    }

    ethereum.on("accountsChanged", (_accounts) => {
      console.log("accountsChanged");
      if (onAccountsChanged) {
        onAccountsChanged(_accounts);
      }
      if (initGetAccount) {
        window.location.reload();
        initGetAccount = true;
      }
    });

    ethereum.on("chainChanged", (chainId) => {
      console.log("chainChanged");
      if (onChainChanged) {
        onChainChanged(chainId);
        return;
      }
      window.location.reload();
    });

    ethereum.on("message", (message) => {
      if (onMessage) {
        onMessage(message);
      }
    });

    resolve(true);
  });
}

async function getGasPrice() {
  try {
    const gasPrice = await web3.eth.getGasPrice();
    console.log("gasPrice:", gasPrice);
    return gasPrice;
  } catch (error) {
    console.error("gasPriceError:", error);
  }
  return 3e9;
}

async function getGasLimit(contractFunc, senderAddress) {
  try {
    const gasEstimate = await contractFunc.estimateGas({ from: senderAddress });
    if (gasEstimate) {
      return gasEstimate * 2 + 250000;
    }
  } catch (e) {}
  return 500000;
}
export default {
  init,
  sign(nonce, account) {
    return web3.eth.personal.sign(nonce, account);
  },
  getAccount() {
    if (typeof window.ethereum === "undefined") {
      window.$Dialog.alert({
        message: window.$$i18n.t("swap.noMetamask"),
      });
      return;
    }
    return ethereum.request({ method: "eth_requestAccounts" });
  },
  getBalance(account) {
    return dprContract.methods.balanceOf(account).call({
      from: account,
    });
  },
  async approveBridge(from, amount) {
    console.log(from, amount);
    const allowance = await dprContract.methods
      .allowance(from, Bridge.address)
      .call();
    console.log(allowance);
    if (new BN(amount).lte(new BN(allowance))) {
      return;
    }
    let maxNum = Array.from({ length: 40 }).reduce((preVal, curVal) => {
      return preVal + "F";
    }, "0X");
    const gasPrice = await getGasPrice();
    const contractFunc = dprContract.methods.approve(Bridge.address, maxNum);
    const gas = await getGasLimit(contractFunc, from);
    return contractFunc.send({ from, gasPrice, gas });
  },
  async withdrawFromSub(deeperChain, ethAddress, amount, signature) {
    console.log("withdrawFromSub");
    console.log(arguments);
    const units = parseBNtoString(amount);
    await this.approveBridge(ethAddress, units);
    const formatedToAddress = u8aToHex(decodeAddress(deeperChain));
    const gasPrice = await getGasPrice();
    const contractFunc = bridgeContract.methods.withdrawTransfer(
      formatedToAddress,
      ethAddress,
      units,
      signature
    );
    const gas = await getGasLimit(contractFunc, ethAddress);
    return contractFunc.send({ from: ethAddress, gasPrice, gas });
  },
  async sendToSubstrate(from, to, amount) {
    console.log("sendToSubstrate");
    const units = parseBNtoString(amount);
    const formatedToAddress = u8aToHex(decodeAddress(to));
    console.log(to);
    console.log("back");
    console.log(encodeAddress(hexToU8a(formatedToAddress)).toString());
    await this.approveBridge(from, units);
    const gasPrice = await getGasPrice();
    const contractFunc = bridgeContract.methods.setTransfer(
      units,
      formatedToAddress
    );
    const gas = await getGasLimit(contractFunc, from);
    return contractFunc.send({ from, gasPrice, gas });
  },
  async getWithdrawBalance(address) {
    return bridgeContract.methods.getUserBalance(address).call();
  },
  async withdrawByUser(messageId, sign, from) {
    const gasPrice = await getGasPrice();
    const contractFunc = bridgeContract.methods.confirmWithdrawTransfer(
      messageId,
      sign
    );
    const gas = await getGasLimit(contractFunc, from);
    return contractFunc.send({ from, gasPrice, gas });
  },
};
