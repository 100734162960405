// Hex	Decimal	Network
// 0x1	1	Ethereum Main Network (Mainnet)
// 0x3	3	Ropsten Test Network
// 0x4	4	Rinkeby Test Network
// 0x5	5	Goerli Test Network
// 0x2a	42	Kovan Test Network

module.exports = {
  "1": {
    id: 1,
    name: "Mainnet",
    rpcUrl: "https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0",
    contracts: {
      bridge: "0x0215cC1eED633665D3Cd385F7a891dF616AB1706",
      dpr: "0xf3ae5d769e153ef72b4e3591ac004e89f48107a1",
    },
    abi: {
      dpr: require('../abi/DPR.abi.json'),
      bridge: require('../abi/Bridge.abi.json')
    },
    etherskanDomain: 'https://etherscan.io/',
  },
  "42": {
    id: 42,
    name: "kovan",
    rpcUrl: "https://kovan.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0",
    contracts: {
      bridge: "0x309ed2c169DeCb81c5969dC9667AA7919170b6Bd",
      dpr: "0x4220bc935d6105f933fc25bfb0c0f5b52af4ddcc"
    },
    abi: {
      dpr: require('../abi/DPR.abi.json'),
      bridge: require('../abi/Bridge.abi.json')
    },
    etherskanDomain: 'https://kovan.etherscan.io/',
  },
  "56": {
    id: 56,
    name: "bsc",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    contracts: {
      bridge: "0x375223d6ed2b7E08bf7Fc552D72ebA403E4B9406",
      dpr: "0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4",
    },
    abi: {
      bridge: require('../abi/BSCBridge.abi.json'),
      dpr: require('../abi/DPR.abi.json'),
    },
    etherskanDomain: 'https://bscscan.com',
  },
}