<template>
  <div class="mainContainer">
    <div style="color: #fff; position: relative; text-align: center">
      {{ $t("recommendBSC") }}
      <div style="margin-top: 20px">
        <button
          style="
            padding: 5px 10px;
            background-color: #fff;
            border-radius: 5px;
            color: #1989fa;
            cursor: pointer;
          "
          @click="switchBSC"
        >
          BSC
        </button>
        <button
          style="
            margin-left: 15px;
            padding: 5px 10px;
            background-color: #fff;
            border-radius: 5px;
            color: #1989fa;
            cursor: pointer;
          "
          @click="switchSol"
        >
          Solana
        </button>
      </div>
    </div>
    <div style="height: 60px"></div>
    <div :class="{ switchBox: true, reverse: activeReverse === 1 }">
      <div
        class="switchBtn"
        @click="activeReverse = activeReverse === 0 ? 1 : 0"
      >
        <van-button
          type="info"
          style="width: 100px; font-size: 20px"
          icon="exchange"
        ></van-button>
      </div>
      <div class="box1">{{ t("Ethereum Bridge") }}</div>
      <div class="box2">to</div>
      <div class="box3">Deeper Chain</div>
    </div>
    <van-tabs class="mainTab" v-model="activeReverse" animated>
      <van-tab style="position: relative">
        <div v-if="ethLoading" class="loadingBox">
          <van-loading color="#1989fa" />
        </div>
        <div class="groupBox">
          <van-cell-group :title="t('swap.ethInfo')">
            <van-cell :title="t('swap.ethAddress')">
              <template #label>
                <div style="word-break: break-all">
                  {{ ethData.account }}
                </div>
              </template>
            </van-cell>
            <van-cell
              :title="t('swap.Balance')"
              :value="(ethData.balance / 1e18).toFixed(4) / 1"
            />
          </van-cell-group>
        </div>

        <div class="groupBox">
          <van-cell-group :title="t('swap.transferInfo')">
            <div style="padding: 20px">
              {{ t("swap.DeeperChain") }}
            </div>
            <van-field
              v-model="form1.address"
              type="textarea"
              :placeholder="t('swap.inputDeeperChain')"
            />
            <div class="stepperContainer">
              <van-stepper
                :decimal-length="0"
                :min="getLimit('toDeeperChainMin')"
                :max="getLimit('toDeeperChainMax')"
                v-model="form1.amount"
              />
              DPR
            </div>
          </van-cell-group>
        </div>

        <div style="padding: 0 30px; margin-top: 20px">
          <van-button
            @click="sendToSubstrate"
            :loading="sendSubstrateLoading"
            block
            type="info"
            :disabled="(ethData.balance / 1e18).toFixed(5) / 1 < form1.amount"
            >{{ t("swap.Send") }}</van-button
          >
        </div>
      </van-tab>
      <van-tab style="position: relative">
        <div v-if="polkadotLoading" class="loadingBox">
          <van-loading color="#1989fa" />
        </div>
        <div class="groupBox">
          <van-cell-group :title="t('swap.deeperChainInfo')">
            <van-cell
              :title="t('swap.DeeperChain')"
              is-link
              @click="showAccountPicker = true"
            >
              <template #label>
                <div v-if="polkaData.account" style="word-break: break-all">
                  ({{ polkaData.account.meta.name }})
                  {{ polkaData.account.address }}
                </div>
              </template>
            </van-cell>
            <van-cell
              :title="t('swap.Balance')"
              :value="(polkaData.balance / 1e18).toFixed(4) / 1"
            />
          </van-cell-group>
        </div>
        <div class="groupBox">
          <van-cell-group :title="t('swap.transferInfo')">
            <div style="padding: 20px">
              {{ t("swap.ethAddress") }}
            </div>
            <van-field
              readonly
              v-model="ethData.account"
              type="textarea"
              :placeholder="t('swap.inputEthAddress')"
            />
            <div class="stepperContainer">
              <van-stepper
                :decimal-length="0"
                :min="getLimit('fromDeeperChainMin')"
                :max="getLimit('fromDeeperChainMax')"
                v-model="form2.amount"
              />
              DPR
            </div>
          </van-cell-group>
        </div>

        <div style="padding: 0 30px; margin-top: 20px">
          <van-button
            :loading="sendEthLoading"
            :disabled="(polkaData.balance / 1e18).toFixed(5) / 1 < form2.amount"
            @click="sendToEth"
            block
            type="info"
            >{{ t("swap.Send") }}</van-button
          >
        </div>
        <div class="tipBox" style="margin-bottom: 20px">
          <div>
            <b>{{ showLimit(todayTotal) }}</b> DPR {{ t("swap.usedToday") }}
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div>
      <div class="tipBox">
        {{ t("swap.SendTips") }}
      </div>

      <div class="tipBox">
        {{ t("swap.SendTips2") }}
      </div>
    </div>
    <table
      style="
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #999;
        margin-top: 20px;
      "
    >
      <thead>
        <tr>
          <th>{{ t("swap.direction") }}</th>
          <th>{{ t("swap.max") }}</th>
          <th>{{ t("swap.min") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ETH => Deeper Chain</td>
          <td>100000 DPR/day</td>
          <td>1000 DPR/day</td>
        </tr>
        <tr>
          <td>Deeper Chain => ETH</td>
          <td>10000 DPR/day</td>
          <td>1000 DPR/day</td>
        </tr>
      </tbody>
    </table>

    <div class="bridgeRecordContainer">
      <div style="display: flex; justify-content: center; align-items: center">
        <h3 class="tableTitle">{{ t("swap.recordList") }}</h3>
        <!-- <van-button type="info" style="width: 50px" :loading="recordLoading" @click="getRecordList()" size="mini" plain icon="replay"></van-button> -->
      </div>
      <div style="text-align: right; padding: 10px 30px">
        <van-button
          @click="searchOrder"
          type="primary"
          icon="search"
        ></van-button>
      </div>
      <table v-if="recordList.length">
        <thead>
          <tr>
            <th>{{ t("swap.time") }}</th>
            <th>{{ t("swap.direction") }}</th>
            <th>{{ t("swap.sender") }}</th>
            <th>{{ t("swap.recipient") }}</th>
            <th>{{ t("swap.amount") }}</th>
            <th>{{ t("swap.status") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in recordList.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )"
            :key="item._id"
          >
            <td>
              {{ $moment(item.insertTime / 1).format("YYYY-MM-DD HH:mm:ss") }}
            </td>
            <td>
              <span v-if="item.direction == 1" style="color: #ff976a"
                >Deeper Chain to {{ t("Ethereum Bridge") }}</span
              >
              <span v-if="item.direction == 2" style="color: #07c160"
                >{{ t("Ethereum Bridge") }} to Deeper Chain</span
              >
            </td>
            <td style="word-break: break-all; width: 150px">
              <span v-if="item.direction == 1">{{ item.substrateSender }}</span>
              <span v-if="item.direction == 2">{{ item.sender }}</span>
            </td>
            <td style="word-break: break-all; width: 150px">
              <span>{{ item.recipient }}</span>
            </td>
            <td>{{ (item.amount / 1e18).toFixed(5) / 1 }} DPR</td>
            <td>
              <div v-if="!item.done">
                <van-tag>{{ t("swap.pending") }}</van-tag>
              </div>
              <div v-else>
                <van-tag type="danger" v-if="item.isError">{{
                  t("swap.error")
                }}</van-tag>
                <van-tag type="success" v-if="!item.isError">{{
                  t("swap.success")
                }}</van-tag>
              </div>
            </td>
            <td v-if="item.done && item.direction == 1 && !item.isError">
              <van-button
                v-if="!item.withdraw"
                size="small"
                :loading="sendEthLoading"
                @click="toWithdrawBalance(item)"
                type="info"
                >{{ t("swap.withdraw") }}</van-button
              >
            </td>
            <td style="word-break: break-all; width: 150px" v-if="item.isError">
              {{ item.errorMessage }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else style="margin: 30px">no record</div>
      <div class="paginationBox">
        <van-pagination
          v-model="currentPage"
          :total-items="recordList.length"
          :items-per-page="pageSize"
        />
      </div>
    </div>

    <van-popup v-model="showAccountPicker" position="bottom">
      <div>
        <div
          @click="confirmAccount(index)"
          class="accountBox"
          v-for="(item, index) in polkaData.accountList"
          :key="item.address"
        >
          {{ `${item.meta.name} - ${item.address}` }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
const Web3 = require("web3");
import ethService from "@/services/eth.js";
import polkadotService from "@/services/polkadot.js";
import jwt from "jsonwebtoken";

function getUrl(path) {
  if (window.$chainID == 56) {
    return "/bridge/bsc" + path;
  }
  return "/bridge/" + path;
}

const MinLimit = 1000;
const DailyMaxLimit = 600000;
function getLimit(type) {
  if (window.$chainID == 56) {
    if (type == "toDeeperChainMax") {
      return 100000;
    }
    if (type == "fromDeeperChainMax") {
      return 5000;
    }
    if (type == "toDeeperChainMin") {
      return 1000;
    }
    if (type == "fromDeeperChainMin") {
      return 1000;
    }
  } else {
    if (type == "toDeeperChainMax") {
      return 100000;
    }
    if (type == "fromDeeperChainMax") {
      return 10000;
    }
    if (type == "toDeeperChainMin") {
      return 1000;
    }
    if (type == "fromDeeperChainMin") {
      return 1000;
    }
  }
}

export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      ethLoading: false,
      polkadotLoading: false,
      activeReverse: 0,
      showAccountPicker: false,
      sendSubstrateLoading: false,
      sendEthLoading: false,
      withdrawBalance: "",
      ethData: {
        account: "",
        balance: "",
      },
      polkaData: {
        accountList: [],
        account: { meta: {} },
        balance: "",
      },
      form1: {
        address: "",
        amount: MinLimit,
      },
      form2: {
        address: "",
        amount: MinLimit,
      },
      recordList: [],
      recordLoading: false,
      todayTotal: 0,
    };
  },
  async mounted() {
    // this.$toast.loading({
    //   message: 'Upgraded...',
    //   forbidClick: true,
    //   loadingType: 'spinner',
    //   duration: 0,
    //   overlay: true
    // });
    // return
    try {
      if (this.$route.query.tab == 1) {
        this.activeReverse = 1;
      }
      ethService.init({
        onAccountsChanged(accounts) {
          window.localStorage.clear();
          console.log("onAccountsChanged");
          window.location.reload();
        },
      });
      this.initPolkadot();
      await this.initEth();
      setInterval(() => {
        this.getRecordList();
      }, 120000);
      this.getRecordList();

      this.getTodayTotal();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    switchSol() {
      window.location.href = "https://deeperchain-solana-swap.deeper.network/";
    },
    async switchBSC() {
      console.log("switch BSC");
      window.location.href =
        "https://deeperchain-bnbchain-swap.deeper.network/";
      return;
      // 请求用户授权访问其 MetaMask 账户
      await window.ethereum.enable();
      // 获取当前的网络 ID
      if (window.ethereum.chainId == 56) {
        this.$Toast.success();
        return;
      }
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // BSC 网络的 Chain ID
            chainName: "Smart Chain",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"], // BSC 节点的 URL
            blockExplorerUrls: ["https://bscscan.com/"], // 区块浏览器的 URL
          },
        ],
      });
      if (window.ethereum.chainId == 56) {
        this.$Toast.success();
        return;
      }
    },
    getLimit,
    showLimit(limit) {
      if (limit >= 1000000) {
        return 1000000;
      }
      return limit;
    },
    getTodayTotal() {
      this.$fetch({
        url: getUrl("/todayTotal"),
      })
        .then((res) => {
          console.log(res);
          this.todayTotal = res.data;
        })
        .finally(() => {});
    },
    t(value) {
      let returnValue = this.$t(value) || value;
      if (window.$chainID == 56) {
        return returnValue
          .replace(/Ethereum/g, "BSC")
          .replace(/eth/g, "bsc")
          .replace(/Eth/g, "Bsc")
          .replace(/ETH/g, "BSC")
          .replace(/eth/g, "bsc");
      }
      return returnValue;
    },
    getData() {
      return Promise.all([
        this.getEthBalance(),
        this.initPolkadot(),
        this.getRecordList(),
      ]);
    },
    async searchOrder() {
      let searchWallet = prompt("Please input wallet address");
      this.$fetch({
        url: getUrl("/records"),
        headers: {
          Authorization: window.localStorage.getItem("BRIDGETOKEN"),
        },
        params: {
          wallet: searchWallet,
        },
      })
        .then((res) => {
          this.currentPage = 1;
          this.recordList = res.data.map((it) => {
            if (it.direction == 2) {
              return {
                ...it,
                recipient: polkadotService.encodeDeeperChain(it.recipient),
              };
            }
            if (it.direction == 1) {
              return {
                ...it,
                substrateSender: polkadotService.encodeDeeperChain(
                  it.substrateSender
                ),
              };
            }
          });
        })
        .finally(() => {});
    },
    async getRecordList() {
      this.$fetch({
        url: getUrl("/records"),
        params: {
          wallet: this.ethData.account,
        },
        headers: {
          Authorization: window.localStorage.getItem("BRIDGETOKEN"),
        },
      })
        .then((res) => {
          this.currentPage = 1;
          this.recordList = res.data.map((it) => {
            if (it.direction == 2) {
              return {
                ...it,
                recipient: polkadotService.encodeDeeperChain(it.recipient),
              };
            }
            if (it.direction == 1) {
              return {
                ...it,
                substrateSender: polkadotService.encodeDeeperChain(
                  it.substrateSender
                ),
              };
            }
          });
        })
        .finally(() => {});
    },

    confirmAccount(index) {
      this.polkaData.account = this.polkaData.accountList[index];
      this.showAccountPicker = false;
      this.getPolkadotBalance();
    },
    async sendToSubstrate() {
      this.sendSubstrateLoading = true;
      let checkAddress = polkadotService.isValidAddressPolkadotAddress(
        this.form1.address
      );
      console.log(checkAddress);
      if (!checkAddress) {
        this.$Dialog.confirm({
          title: "Error",
          message: "invalid Deeper Chain address",
        });
        this.sendSubstrateLoading = false;
        return;
      }
      let res = await ethService
        .sendToSubstrate(
          this.ethData.account,
          this.form1.address,
          this.form1.amount
        )
        .catch((e) => {
          console.log(e);
          this.$Dialog.confirm({
            title: "Error",
            message: e.message,
          });
        })
        .finally(() => {
          this.sendSubstrateLoading = false;
        });
      if (res && res.blockHash) {
        this.$Toast.success();
        this.getData();
        this.form1.amount = MinLimit;
      }
    },
    async toWithdrawBalance(item) {
      console.log(item);
      this.sendEthLoading = true;
      await ethService
        .withdrawByUser(item.MessageID, item.bridgeSign, this.ethData.account)
        .catch((e) => {
          this.$Dialog.confirm({
            title: "Error",
            message: e.message,
          });
        })
        .finally(() => {
          this.sendEthLoading = false;
          this.getData();
          this.form2.amount = MinLimit;
        });
    },
    async sendToEth() {
      await this.getTodayTotal();
      console.log(this.todayTotal, this.form2.amount);
      if (
        this.todayTotal > 0 &&
        this.todayTotal / 1 + this.form2.amount / 1 > DailyMaxLimit
      ) {
        await this.$Dialog.confirm({
          confirmButtonText: this.$t("Continue"),
          cancelButtonText: this.$t("Cancel"),
          message: this.$t(
            "The quota for this period has been exhausted. You can wait a few hours before applying again. Your current withdrawal is likely to fail. Would you like to proceed?"
          ),
        });
      }
      this.sendEthLoading = true;
      console.log(this.polkaData);
      let signature = await polkadotService
        .signMessage(this.polkaData.account.address, this.ethData.account)
        .catch((e) => {
          // this.$Dialog.confirm({
          //   title: 'Error',
          //   message: e.message,
          // });
        });
      if (!signature) {
        this.sendEthLoading = false;
        return;
      }
      console.log(signature);
      return ethService
        .withdrawFromSub(
          this.polkaData.account.address,
          this.ethData.account,
          this.form2.amount,
          signature
        )
        .finally(() => {
          this.sendEthLoading = false;
          this.getData();
        })
        .catch((e) => {
          console.log(e);
          this.$Dialog.confirm({
            title: "Error",
            message: e.message,
          });
        });
    },
    async initEth() {
      this.ethLoading = true;
      let accounts = await ethService.getAccount();
      if (!accounts) {
        this.ethLoading = false;
        return;
      }
      this.ethData.account = accounts[0];
      this.ethLoading = false;
      await this.getEthBalance();
    },
    async getEthBalance() {
      this.ethLoading = true;
      let balance = await ethService.getBalance(this.ethData.account);
      this.ethData.balance = balance;
      this.ethLoading = false;
      return;
    },
    async initPolkadot() {
      this.polkadotLoading = true;
      await polkadotService.init();
      const accounts = await polkadotService.getAccount();
      this.polkaData.accountList = accounts;
      if (!accounts.length) {
        this.$Dialog.alert({
          message: this.t("swap.noPolkadot"),
        });
        return;
      }
      if (!(this.polkaData.account && this.polkaData.account.address)) {
        this.polkaData.account = accounts[0];
      }
      this.polkadotLoading = false;
      await this.getPolkadotBalance();
    },
    async getPolkadotBalance() {
      if (this.polkaData.account) {
        this.polkadotLoading = true;
        const balance = await polkadotService.getBalance(
          this.polkaData.account.address
        );
        this.polkaData.balance = balance;
        this.polkadotLoading = false;
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.accountBox {
  font-size: 14px;
  padding: 10px;
  margin: 10px 0;
  color: #999;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: #333;
    background: #eee;
  }
  &:active {
    color: #000;
    background: #f5f5f5;
  }
}
.groupBox {
  background: #fff;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 20px;
  /deep/ .van-cell-group__title {
    position: relative;
    color: #2c7bfd;
    font-weight: bold;
    font-size: 18px;
    height: 60px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 16px;
      bottom: 20%;
      height: 3px;
      width: 50px;
      background: #2c7bfd;
    }
  }
  /deep/ .van-field__control {
    background: #f5f5f5;
    padding: 20px;
  }
}
.bridgeRecordContainer {
  padding: 30px 0;
  font-size: 12px;
  margin: 30px -200px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  .tableTitle {
    color: #2c7bfd;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
  }
  table {
    width: 100%;
    td {
      padding: 10px;
    }
  }
}
.paginationBox {
  width: 450px;
  margin: 0 auto;
}
.mainContainer {
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.mainTab {
  /deep/ .van-tabs__wrap {
    display: none;
  }
}
.stepperContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  /deep/ .van-stepper__input {
    width: 50%;
  }
}
.tipBox {
  margin-top: 20px;
  color: #999;
  text-align: center;
  font-size: 12px;
}
.loadingBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9;
}
.switchBox {
  position: relative;
  height: 50px;
  padding-top: 12px;
  text-align: center;
  background: #fff;
  font-size: 18px;
  border-radius: 5px;
  &.reverse {
    .box1 {
      left: 40%;
    }
    .box3 {
      left: 0;
    }
  }
  .box1 {
    position: absolute;
    left: 0;
    width: 30%;
    height: 20px;
    transition: all 0.3s ease;
    color: #000;
    font-weight: bold;
  }
  .box2 {
    position: absolute;
    left: 30%;
    width: 10%;
    height: 20px;
    transition: all 0.3s ease;
    color: #2c7bfd;
    font-weight: bold;
  }
  .box3 {
    position: absolute;
    left: 40%;
    width: 30%;
    height: 20px;
    transition: all 0.3s ease;
    color: #000;
    font-weight: bold;
  }
  .switchBtn {
    position: absolute;
    right: 3px;
    top: 2px;
    z-index: 9;
    font-size: 25px;
  }
}
@media screen and (max-width: 600px) {
  .bridgeRecordContainer {
    width: 100%;
    margin: 0;
  }
  .paginationBox {
    width: 100%;
  }
}
</style>
